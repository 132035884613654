import { render, staticRenderFns } from "./organizationsAdd.vue?vue&type=template&id=42c92e0c&scoped=true&"
import script from "./organizationsAdd.vue?vue&type=script&lang=js&"
export * from "./organizationsAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42c92e0c",
  null
  
)

export default component.exports