<template>
  <div class="search">
    <h4 style="margin-top: 0">Поиск организации</h4>
    <el-row :gutter="10">
      <el-col :span="18">
        <el-select
          clearable
          @change="searchOrganizationByQuery"
          class="searchPole"
          v-model="search.organization"
          filterable
          remote
          value-key="organizationInfo.id"
          reserve-keyword
          placeholder="Организация"
          :remote-method="searchOrganization"
        >
          <el-option
            v-for="item in organizations.content"
            :key="item.id"
            :label="item.organizationInfo.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button @click="clearSearch" type="success">Сброс</el-button>
        <el-button @click="setShowModal('addOrg')" type="primary">
          Добавить организацию</el-button
        >
      </el-col>
    </el-row>

    <br />
    <el-row :gutter="10">
      <el-col :span="24"> </el-col>
    </el-row>

    <custom-modal
      :title="'Добавить организацию'"
      :visible="showModal === 'addOrg'"
    >
      <organizations-add></organizations-add>
    </custom-modal>
  </div>
</template>

<script>
import OrganizationsAdd from "@/components/admin/organizations/organizationsAdd";
import { mapGetters } from "vuex";
import CustomModal from "@/components/customModal";
export default {
  name: "organizationsSearch",
  components: { CustomModal, OrganizationsAdd },
  data() {
    return {
      search: {
        organization: {},
      },
      dialogVisible: false,
      searchParam: "",
    };
  },

  computed: {
    ...mapGetters({
      organizations: "GETORGANIZATIONS",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    setShowModal(value) {
      this.$store.dispatch("setShowModal", value);
    },

    clearSearch() {
      this.$store.dispatch("getAllOrganizations", {
        page: 0,
        size: 20,
      });
      this.search = {
        organization: {},
      };
    },

    searchOrganizationByQuery() {
      let data = {
        query: this.search.organization.organizationInfo.name,
        page: 0,
        size: 20,
      };
      this.$store.dispatch("searchOrganizations", data);
    },

    searchOrganization(query) {
      this.getOrganizationList(query);
    },

    getOrganizationList(query) {
      let data = {
        query: query,
        page: 0,
        size: 20,
      };
      this.$store.dispatch("searchOrganizations", data);
    },
  },
};
</script>

<style scoped></style>
