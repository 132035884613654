<template>
  <div>
    <h2>Организации</h2>

    <organizations-search></organizations-search>

    <h2>Список организаций</h2>
    <organizations-table :organizations="organizations"></organizations-table>
  </div>
</template>

<script>
import OrganizationsSearch from "@/components/admin/organizations/organizationsSearch";
import OrganizationsTable from "@/components/admin/organizations/organizationsTable";
import { mapGetters } from "vuex";
export default {
  name: "organizationsMain",
  components: { OrganizationsTable, OrganizationsSearch },
  computed: {
    ...mapGetters({
      organizations: "GETORGANIZATIONS",
    }),
  },

  mounted() {
    this.$store.dispatch("getAllOrganizations");
  },
};
</script>

<style scoped></style>
