<template>
  <div>
    <el-form
      :model="addOrganizationsForm"
      :rules="rules"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-form-item prop="egrId" label="ЕГР">
        <el-select
          clearable
          class="searchPole"
          v-model="addOrganizationsForm.egrId"
          filterable
          remote
          value-key="id"
          reserve-keyword
          placeholder="ЕГР"
          :remote-method="searchNames"
        >
          <el-option
            v-for="item in egrNames"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        placeholder="Наименование"
        prop="organizationTitle"
        label="Наименование"
      >
        <el-input v-model="addOrganizationsForm.organizationTitle"></el-input>
      </el-form-item>
      <el-button type="primary" @click="addOrganization">Добавить</el-button>
    </el-form>
  </div>
</template>

<script>
import notification from "@/mixins/notification";
import { mapGetters } from "vuex";

export default {
  name: "organizationsAdd",
  mixins: [notification],
  data() {
    return {
      search: {
        id: null,
        name: null,
      },
      addOrganizationsForm: {
        egrId: null,
        organizationTitle: "",
      },
      rules: {
        organizationTitle: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      egrIds: "GETEGRIDSLIST",
      egrNames: "GETEGRNAMESLIST",
    }),
  },

  methods: {
    searchNames(query) {
      if (query.length >= 3) {
        this.getEgrNames(query);
      }
    },

    getEgrNames(query) {
      this.$store.dispatch("getEgrNames", { query: query });
    },

    searchIds(query) {
      this.getEgrIds(query);
    },

    getEgrIds(query) {
      this.$store.dispatch("getEgrIds", { query: query });
    },

    addOrganization() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          let data = {
            organizationInfo: {
              name: this.addOrganizationsForm.organizationTitle,
            },
          };
          if (this.addOrganizationsForm.egrId !== null) {
            data.egrInfo = {
              id: this.addOrganizationsForm.egrId,
            };
          }
          this.$store.dispatch("addNewOrganization", data).then((response) => {
            console.log(response);
            this.notification("Успешно", "Организация добавлена", "success");
            this.$store.dispatch("getAllOrganizations", {
              page: 0,
              size: 20,
            });
            this.$store.dispatch("setShowModal", null);
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped></style>
