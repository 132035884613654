<template>
  <div>
    <el-form
      :model="editOrganizationsForm"
      ref="formedForm"
      :rules="rules"
      label-position="top"
      label-width="120px"
    >
      <el-form-item prop="egrId" label="ЕГР">
        <el-select
          clearable
          class="searchPole"
          v-model="editOrganizationsForm.egrInfo"
          filterable
          remote
          value-key="id"
          reserve-keyword
          placeholder="ЕГР"
          :remote-method="searchNames"
        >
          <el-option
            v-for="item in egrNames"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="organizationTitle" label="Наименование">
        <el-input
          placeholder="Наименование"
          v-model="editOrganizationsForm.organizationInfo.name"
        ></el-input>
      </el-form-item>
      <el-button type="primary" @click="editOrganization">Сохранить</el-button>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";

export default {
  props: ["editOrganizationsForm"],
  name: "organizationsEdit",
  mixins: [notification],

  data() {
    return {
      rules: {
        organizationTitle: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      egrIds: "GETEGRIDSLIST",
      egrNames: "GETEGRNAMESLIST",
    }),
  },

  methods: {
    searchIds(query) {
      this.getEgrIds(query);
    },

    searchNames(query) {
      if (query.length >= 3) {
        this.getEgrNames(query);
      }
    },

    getEgrNames(query) {
      this.$store.dispatch("getEgrNames", { query: query });
    },

    getEgrIds(query) {
      this.$store.dispatch("getEgrIds", { query: query });
    },

    editOrganization() {
      this.$store
        .dispatch("editOrganization", this.editOrganizationsForm)
        .then((response) => {
          if (response.data.egrInfo !== undefined) {
            this.notification("Успешно", "Организация изменена", "success");
            this.$store.dispatch("setShowModal", null);
          }
        });
    },
  },
};
</script>

<style scoped></style>
