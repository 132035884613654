<template>
  <div>
    <organizations-main></organizations-main>
  </div>
</template>

<script>
import OrganizationsMain from "@/components/admin/organizations/organizationsMain";
export default {
  name: "organizationsMainView",
  components: { OrganizationsMain },
};
</script>

<style scoped></style>
