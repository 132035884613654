<template>
  <div>
    <el-table stripe border v-loading="loadData" :data="organizations.content">
      <el-table-column
        label="Наименование"
        prop="organizationInfo.name"
      ></el-table-column>
      <el-table-column label="ЕГР" prop="egrInfo.name"></el-table-column>
      <el-table-column label="">
        <template slot-scope="row">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="float: right"
                @click="editOrganization(row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                size="mini"
                style="float: right; margin-right: 10px"
                type="danger"
                @click="deleteOrganization(row.row)"
                >Удалить</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="organizations.size"
      :current-page.sync="organizations.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="organizations.totalElements"
    >
    </el-pagination>

    <custom-modal
      :title="'Изменить организацию'"
      :visible="showModal === 'updateOrg'"
    >
      <organizations-edit
        :edit-organizations-form="selectedOrganization"
      ></organizations-edit>
    </custom-modal>
  </div>
</template>

<script>
import OrganizationsEdit from "@/components/admin/organizations/organizationsEdit";
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";
import CustomModal from "@/components/customModal";
export default {
  components: { CustomModal, OrganizationsEdit },
  mixin: [notification],
  props: ["organizations"],
  name: "organizationsTable",
  data() {
    return {
      loadData: true,
      pageOptions: {
        page: 0,
        size: 20,
      },
      dialogVisible: false,
      selectedOrganization: {},
    };
  },

  computed: {
    ...mapGetters({
      organizations: "GETORGANIZATIONS",
      showModal: "GETSHOWMODAL",
    }),

    getPaginationOptions: {
      get() {
        return {
          page: this.organizations.number,
          size: this.organizations.size,
        };
      },
    },
  },

  methods: {
    handleSizeChange(val) {
      this.getAllOrganizations({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getAllOrganizations({
        page: val - 1,
        size: this.organizations.size,
      });
    },

    deleteOrganization(item) {
      console.log(item);
      this.confirm(
        "Удалить организацию?",
        "Вы уверены что хотите удалить организацию?",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store
          .dispatch("deleteOrganization", item.organizationInfo.id)
          .catch((error) => {
            this.notification("Ошибка", error.response.data.message, "error");
          })
          .then((response) => {
            if (response.data === "Organization has been deleted") {
              this.notification("Успешно", "Организация удалена", "success");
              this.getAllOrganizations(this.getPaginationOptions);
            }
          });
      });
    },

    editOrganization(row) {
      this.$store.dispatch("setShowModal", "updateOrg");
      this.selectedOrganization = row.row;
      console.log(row.row);
    },

    async getAllOrganizations(data) {
      await this.$store.dispatch("getAllOrganizations", data);
    },
  },

  async mounted() {
    await this.getAllOrganizations(this.pageOptions);
    this.loadData = false;
  },
};
</script>

<style scoped></style>
